"use client";
import React from "react";
import { BRAND_LIST } from "@/types/BrandsList";
import BrandItem from "@/components/landing/Brands/BrandItem";
import Marquee from "react-fast-marquee";
import { useGetBrandsService } from "@/services/Shareds/Brands/useGetBrands";
import BrandItemSkeletion from "@/components/landing/Brands/BrandItemSkeletion";

const Brands = () => {
  const { data, isLoading } = useGetBrandsService();
  return (
    <>
      <Marquee
        autoFill
        gradient
        gradientColor="#fff"
        speed={20}
        pauseOnHover
        className="bg-extended_neutral-N0 py-component-y-lg overflow-hidden rounded-page"
      >
        <section className="flex items-center justify-between gap-x-[20px]">
          {isLoading
            ? Array(15)
                .fill(15)
                .map((brand, index) => <BrandItemSkeletion key={index} />)
            : data?.responseData?.map((brand, index) => (
                <BrandItem key={brand?.id} item={brand} image={brand?.image_url || ""} />
              ))}
        </section>
      </Marquee>
    </>
  );
};

export default Brands;
