import React, { ReactNode } from "react";
import cn from "clsx";
import { XMarkCircle, XMarkNormal } from "../icons";

type Sizes = "large" | "medium" | "small";
type Variants = "solid" | "bordered" | "light" | "faded";
type Colors =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning";
type Props = {
  size?: Sizes;
  color?: Colors;
  variant?: Variants;
  className?: string;
  children: ReactNode;
  isDisabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClose?: (event: Event) => void;
  onClick?: (event: string) => void;
  style?: React.CSSProperties;
};

const Chip = (props: Props) => {
  const {
    size = "medium",
    color = "default",
    variant = "solid",
    className = "",
    children,
    endIcon,
    isDisabled = false,
    onClose,
    startIcon,
    onClick,
      style
  } = props;
  return (
    <div
        style={style}
      className={cn(
        "rounded-[20px] flex justify-center items-center select-none transition-all duration-500 gap-1 relative",
        { "px-2 py-[6px] text-button": size == "small" },
        { "px-3 py-[6px] text-buttonMd": size == "medium" },
        { "px-4 py-2 text-buttonLg": size == "large" },
        { "pr-7": onClose },
        {
          "bg-extended_neutral-N100 hover:bg-extended_neutral-N200 hover:text-extended_neutral-N0 border border-extended_neutral-N300 hover:border-extended_neutral-N400":
            variant == "solid" && color == "default",
        },
        {
          "border border-extended_neutral-N100 hover:border-extended_neutral-N200 bg-extended_neutral-N0":
            variant == "bordered" && color == "default",
        },
        {
          "bg-extended_neutral-N100 border border-extended_neutral-N600 hover:border-extended_neutral-N200 opacity-25 hover:opacity-100":
            variant == "faded" && color == "default",
        },
        {
          "hover:bg-extended_neutral-N200 hover:text-extended_neutral-N0":
            variant == "light" && color == "default",
        },
        {
          "bg-extended_neutral-N200 text-extended_neutral-N700 hover:bg-extended_neutral-N700 hover:text-extended_neutral-N0 border border-extended_neutral-N700 hover:border-extended_neutral-N700":
            variant == "solid" && color == "primary",
        },
        {
          "bg-extended_neutral-N200 border border-extended_neutral-N700 text-extended_neutral-N700 hover:border-extended_neutral-N700 opacity-25 hover:opacity-100":
            variant == "faded" && color == "primary",
        },
        {
          "border border-extended_neutral-N600 text-extended_neutral-N600 hover:border-extended_neutral-N700 bg-extended_neutral-N0":
            variant == "bordered" && color == "primary",
        },
        {
          "text-extended_neutral-N600 hover:bg-extended_neutral-N700 hover:text-extended_neutral-N0":
            variant == "light" && color == "primary",
        },
        {
          "bg-extended_orange-B100 text-extended_orange-B600 hover:bg-extended_orange-B600 hover:text-extended_neutral-N0 border border-extended_orange-B500 hover:border-extended_orange-B600":
            variant == "solid" && color == "secondary",
        },
        {
          "bg-extended_orange-B100 border border-extended_orange-B500 text-extended_orange-B500 hover:border-extended_orange-B600 opacity-25 hover:opacity-100":
            variant == "faded" && color == "secondary",
        },
        {
          "border border-extended_orange-B500 text-extended_orange-B500 hover:border-extended_orange-B600 bg-extended_neutral-N0 hover:bg-extended_orange-B600 hover:text-extended_orange-B600":
            variant == "bordered" && color == "secondary",
        },
        {
          "text-extended_orange-B400 hover:bg-extended_orange-B500 hover:text-extended_neutral-N0":
            variant == "light" && color == "secondary",
        },
        {
          "bg-extended_green-G50 text-extended_green-G500 hover:bg-extended_green-G500 hover:text-extended_neutral-N0 border border-extended_green-G500 hover:border-extended_green-G500":
            variant == "solid" && color == "success",
        },
        {
          "bg-extended_green-G50 border border-extended_green-G500 text-extended_green-G500 hover:border-extended_extended_green-G500 opacity-25 hover:opacity-100":
            variant == "faded" && color == "success",
        },
        {
          "border border-extended_green-G200 text-extended_green-G200 hover:border-extended_extended_green-G300":
            variant == "bordered" && color == "success",
        },
        {
          "text-extended_green-G200 hover:bg-extended_green-G300 hover:text-extended_neutral-N0":
            variant == "light" && color == "success",
        },
        {
          "bg-extended_red-R100 text-extended_neutral-N0 hover:bg-extended_red-R500 hover:text-extended_neutral-N0 border border-extended_red-R500":
            variant == "solid" && color == "danger",
        },
        {
          "bg-extended_red_R100 border border-extended_red-R500 text-extended_red-R500 hover:border-extended_extended_red-R500 opacity-25 hover:opacity-100":
            variant == "faded" && color == "danger",
        },
        {
          "border border-extended_red-R400 text-extended_red-R400 hover:border-extended_extended_red-R500":
            variant == "bordered" && color == "danger",
        },
        {
          "text-extended_red-R400 hover:bg-extended_red-R500 hover:text-extended_neutral-N0":
            variant == "light" && color == "danger",
        },
        {
          "bg-extended_yellow-Y500 text-extended_neutral-N0 hover:bg-extended_yellow-Y600 hover:text-extended_neutral-N0":
            variant == "solid" && color == "warning",
        },
        {
          "bg-extended_yellow-Y100 border border-extended_yellow-Y600 text-extended_yellow-Y600 hover:border-extended_extended_yellow-Y600 opacity-25 hover:opacity-100":
            variant == "faded" && color == "warning",
        },
        {
          "border border-extended_yellow-Y500 text-extended_yellow-Y500 hover:border-extended_extended_yellow-Y600":
            variant == "bordered" && color == "warning",
        },
        {
          "text-extended_yellow-Y500 hover:bg-extended_yellow-Y600 hover:text-extended_neutral-N0":
            variant == "light" && color == "warning",
        },
        className
      )}
      onClick={(e) => onClick && onClick(children as string)}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
      {onClose && (
        <button className="absolute right-1" onClick={(e: any) => onClose(e)}>
          <XMarkNormal
            size={18}
            className={cn(
              "border rounded-full ",
              {
                "bg-extended_neutral-N100 hover:bg-extended_neutral-N200 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "default",
              },
              {
                "border border-extended_neutral-N100 hover:border-extended_neutral-N200":
                  variant == "bordered" && color == "default",
              },
              {
                "hover:bg-extended_neutral-N200 hover:text-extended_neutral-N0":
                  variant == "light" && color == "default",
              },
              {
                "bg-extended_neutral-N600 text-extended_neutral-N0 hover:bg-extended_neutral-N700 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "primary",
              },
              {
                "border border-extended_neutral-N600 text-extended_neutral-N600 hover:border-extended_neutral-N700":
                  variant == "bordered" && color == "primary",
              },
              {
                "text-extended_neutral-N600 hover:bg-extended_neutral-N700 hover:text-extended_neutral-N0":
                  variant == "light" && color == "primary",
              },
              {
                "bg-extended_orange-B500 text-extended_neutral-N0 hover:bg-extended_orange-B600 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "secondary",
              },
              {
                "border border-extended_orange-B500 text-extended_orange-B500 hover:border-extended_orange-B600":
                  variant == "bordered" && color == "secondary",
              },
              {
                "text-extended_orange-B400 hover:bg-extended_orange-B500 hover:text-extended_neutral-N0":
                  variant == "light" && color == "secondary",
              },
              {
                "bg-extended_green-G200 text-extended_neutral-N0 hover:bg-extended_green-G300 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "success",
              },
              {
                "border border-extended_green-G200 text-extended_green-G200 hover:border-extended_extended_green-G300":
                  variant == "bordered" && color == "success",
              },
              {
                "text-extended_green-G200 hover:bg-extended_green-G300 hover:text-extended_neutral-N0":
                  variant == "light" && color == "success",
              },
              {
                "bg-extended_red-R400 text-extended_neutral-N0 hover:bg-extended_red-R500 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "danger",
              },
              {
                "border border-extended_red-R400 text-extended_red-R400 hover:border-extended_extended_red-R500":
                  variant == "bordered" && color == "danger",
              },
              {
                "text-extended_red-R400 hover:bg-extended_red-R500 hover:text-extended_neutral-N0":
                  variant == "light" && color == "danger",
              },
              {
                "bg-extended_yellow-Y500 text-extended_yellow-Y700 border-extended_yellow-Y800 hover:border-extended_yellow-Y500 hover:bg-extended_yellow-Y700 hover:text-extended_neutral-N0":
                  variant == "solid" && color == "warning",
              },
              {
                "border border-extended_yellow-Y500 text-extended_yellow-Y500 hover:text-extended_yellow-Y700 hover:bg-extended_yellow-Y500 hover:border-extended_extended_yellow-Y600":
                  variant == "bordered" && color == "warning",
              },
              {
                "text-extended_yellow-Y500 hover:bg-extended_yellow-Y600 hover:border-extended_yellow-Y500 hover:bg-extended_yellow-Y700 hover:text-extended_neutral-N0":
                  variant == "light" && color == "warning",
              }
            )}
          />
        </button>
      )}
    </div>
  );
};

export default Chip;
