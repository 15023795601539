import React from 'react';
import Image from "next/image";
import LazyImage from "@/components/ui/LazyImage";
import Link from 'next/link';
import { CompanyRoutes } from '@/routes/companyRoutes';
import { Brand } from '@/models/Brand';
import { titleToSlug } from '@/utils/stringUtils';
import useMedia from '@/hooks/useMedia';
type IBrandItem  = {
    image:string;
    alt?:string;
    item?:Brand
}
const BrandItem = (props:IBrandItem) => {
    const isMobile = useMedia(992)
    const {image,alt="",item} = props;
    return (
        <Link target={isMobile ? "_self" : "_blank"} href={CompanyRoutes.brand(titleToSlug(item?.title || ""),item?.id?.toString() || "")} className={`flex w-[70px] h-[70px] aspect-square justify-center items-center rounded-component relative`}>
            <LazyImage src={image} alt={alt} width={70} height={70} className="object-contain w-full h-full" />
        </Link>
    );
};

export default BrandItem;