import React from "react";
import NovaImage from "@/components/ui/NovaImage";

type Props = {
  className?: string;
};
const BannerCardSkeleton = (props: Props) => {
  const { className = "" } = props;
  return (
    <div
      className={`flex rounded-page aspect-[16/6] overflow-hidden ${className}`}
    >
      <figure className="w-full h-full relative bg-slate-500/10 animate-pulse">
        <div className="w-full h-full object-cover object-left"></div>
      </figure>
    </div>
  );
};

export default BannerCardSkeleton;
