import React from "react";
import NovaImage from "@/components/ui/NovaImage";
import Link from "next/link";

type Props = {
  image: string;
  className?: string;
  url: string;
};
const BannerCard = (props: Props) => {
  const { image, className = "", url } = props;
  return (
    <Link
      href={url}
      className={`flex rounded-page overflow-hidden w-full hover:scale-[.99] transition duration-300 ${className}`}
    >
      <figure className="w-full h-full relative ">
        <NovaImage
          src={image}
          alt=""
          width={800}
          height={500}
          className="w-full h-full object-cover object-left"
        />
      </figure>
    </Link>
  );
};

export default BannerCard;
