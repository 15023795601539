import React from "react";
import useSWR from "swr";
import { AxiosError } from "axios";
import Base from "@/models/base/Base";
import { fetcher } from "@/utils/fetcher";
import { Brand } from "@/models/Brand";

type Props = {
  key?: string;
  data?: any;
};

const BASE_URL = "/v1/brand/brands";
export const useGetBrandsService = (props?: Props) => {
  const { data, isLoading, error, isValidating, mutate } = useSWR<
    Base<Brand[]>,
    AxiosError
  >(BASE_URL, () => fetcher<Base<Brand[]>>(BASE_URL, "GET"), {
    revalidateOnFocus: false,
  });
  return { data, isLoading, error, isValidating, mutate };
};
