"use client";
import React from "react";
import BannerCard from "@/components/shareds/Banner/BannerCard";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import BannerWrapperSkeleton from "@/components/shareds/Banner/BannerWrapperSkeleton";
import { useGetSiteSettingsService } from "@/services/Shareds/Settings/useGetSiteSettings";

type Props = {
  banners: string[];
  isLanding?:boolean
};

const BannerWrapper = (props: Props) => {
  const { banners } = props;
  const { data: landing, isLoading: landingLoading } =
    useGetLandingSiteSettingsService();
  const { data: site, isLoading: siteLoading } = useGetSiteSettingsService({isLanding:props.isLanding});
  if (landingLoading || siteLoading) return <BannerWrapperSkeleton />;
  return (
    <section className="flex flex-col tablet:flex-row justify-center items-start gap-component-x w-full">
      {banners.map((item, index) => {
        return (
          <BannerCard
            key={index}
            className="flex-1"
            url={
              landing?.responseData?.data?.find(
                (banner) => banner.description == (item as any)
              )?.data?.url ||
              site?.responseData?.data?.find(
                (banner) => banner.description == (item as any)
              )?.data?.url ||
              ""
            }
            image={
              landing?.responseData?.data?.find(
                (banner) => banner.description == (item as any)
              )?.image_url ||
              site?.responseData?.data?.find(
                (banner) => banner.description == (item as any)
              )?.image_url ||
              ""
            }
          />
        );
      })}
    </section>
  );
};

export default BannerWrapper;
