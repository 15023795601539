import React from "react";
import LazyImage from "@/components/ui/LazyImage";

const BrandItemSkeletion = () => {
  return (
    <div
      className={`flex w-[70px] h-[70px] aspect-square justify-center items-center rounded-component relative bg-slate-500/10 animate-pulse`}
    ></div>
  );
};

export default BrandItemSkeletion;
