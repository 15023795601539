import useSWR from "swr";
import { AxiosError } from "axios";
import Base from "@/models/base/Base";
import { fetcher } from "@/utils/fetcher";
import { SiteSetting } from "@/models/SiteSetting";

type Props = {
  isLanding?:boolean
  key?: string;
  data?: any;
};

const BASE_URL = "/v1/home/site-setting";
export const useGetSiteSettingsService = (props?: Props) => {
  const { data, isLoading, error, isValidating, mutate } = useSWR<
    Base<{ data: SiteSetting[] }>,
    AxiosError
  >(
    props?.isLanding ? null : props?.key + BASE_URL,
    () =>
      fetcher<Base<{ data: SiteSetting[] }>>(BASE_URL, "POST", {
        sitesetting: [
          "campaign_banner",
          "top_banner",
          "banner_card_right_2",
          "banner_card_left_2",
        ],
      }),
    {
      revalidateOnFocus: false,
    }
  );
  return { data, isLoading, error, isValidating, mutate };
};
