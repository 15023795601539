"use client";
import React from "react";
import BannerCard from "@/components/shareds/Banner/BannerCard";
import BannerCardSkeleton from "@/components/shareds/Banner/BannerCardSkeleton";

const BannerWrapperSkeleton = () => {
  return (
    <section className="flex flex-col w-full tablet:flex-row justify-center items-start gap-component-x">
      <BannerCardSkeleton className="flex-1" />
      <BannerCardSkeleton className="flex-1" />
    </section>
  );
};

export default BannerWrapperSkeleton;
